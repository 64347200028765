import React, { useState } from 'react';
import '../stylings/Contact.css';
import dropdownIcon from '../icons/mellowDropDownTwo_ICON.png';
import { CheckCircle2 } from 'lucide-react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    reason: '',
    message: '',
    subscribe: false,
  });

  const [notification, setNotification] = useState({
    message: '',
    visible: false,
    type: 'success',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        'https://k55dgmsolc.execute-api.us-east-1.amazonaws.com/default/contactFormSub',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setNotification({
          message: 'Thank you for contacting Mellow Heights! Your message has been submitted successfully.',
          visible: true,
          type: 'success',
        });

        setFormData({
          name: '',
          email: '',
          reason: '',
          message: '',
          subscribe: false,
        });
      } else {
        setNotification({
          message: `Error: ${data.error || 'Failed to submit your message. Please try again later.'}`,
          visible: true,
          type: 'error',
        });
      }
    } catch (error) {
      setNotification({
        message: `Error: ${error.message}`,
        visible: true,
        type: 'error',
      });
    }

    setTimeout(() => {
      setNotification({ message: '', visible: false, type: 'success' });
    }, 5000);
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Mellow Heights</h1>
      <p className="contact-subtitle">
        We're excited to hear from you! Whether it's feedback, a partnership idea, or support—reach out below.
      </p>

      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className="form-label">Your Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className="form-label">Your Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group select-group">
          <label htmlFor="reason" className="form-label">Reason for Contact</label>
          <div className="dropdown-wrapper">
            <select
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              className="form-select"
              required
            >
              <option value="" disabled>Select a reason</option>
              <option value="support">Customer Support</option>
              <option value="feedback">Product Feedback</option>
              <option value="collaboration">Collaboration</option>
              <option value="sponsorship">Sponsorship</option>
              <option value="partnership">Business Partnership</option>
              <option value="other">Other</option>
            </select>
            <img src={dropdownIcon} alt="Dropdown Icon" className="dropdown-icon" />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message" className="form-label">Your Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="form-textarea"
            placeholder="Type your message here"
            required
          />
        </div>

        {/* Enhanced Newsletter Subscription */}
        <div className="form-group newsletter-section">
          <div className="newsletter-container">
            <div className="newsletter-header">
              <CheckCircle2 className="newsletter-icon" />
              <h3 className="newsletter-title">Stay in the Loop</h3>
            </div>
            <p className="newsletter-description">
              Join our community and receive mindful updates about new features, wellness tips, and exclusive offers.
            </p>
            <label className="newsletter-toggle">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  id="subscribe"
                  name="subscribe"
                  checked={formData.subscribe}
                  onChange={handleChange}
                  className="toggle-input"
                />
                <div className="toggle-slider" />
                <div className="toggle-button" />
              </div>
              <span className="toggle-label">
                Yes, I'd love to receive updates
              </span>
            </label>
          </div>
        </div>

        <button type="submit" className="submit-btn">Submit</button>
      </form>

      {notification.visible && (
        <div className={`notification-bubble ${notification.type}`}>
          <p>{notification.message}</p>
        </div>
      )}
    </div>
  );
};

export default Contact;