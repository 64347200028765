import React from 'react';
import { X } from 'lucide-react';

const BlogPostModal = ({ post, onClose }) => {
  if (!post) return null;

  const blogContent = {
    "Why Humidity Matters: Keeping Ingredients Fresh": {
      content: `
      <p>Maintaining freshness is essential for preserving the quality, potency, and flavor of your cannabis. At Mellow Heights, we understand the science behind humidity control and its crucial role in enhancing your cannabis experience. With proper humidity regulation, your cannabis stays fresh, flavorful, and potent for longer.</p>

      <h3>Understanding Humidity's Impact</h3>
      <p>Humidity levels between 59-62% create the ideal environment for cannabis storage. Below this range, your buds become dry, leading to harsher smoke and a loss of terpenes. On the other hand, higher levels risk mold growth. This delicate balance is key to maintaining the aroma, potency, and flavor of your cannabis.</p>

      <h3>The HydroLid Difference</h3>
      <p>Our innovative HydroLid system is designed to maintain optimal humidity levels for your cannabis through:
        <ul>
          <li>Two-way humidity regulation that releases or absorbs moisture as needed</li>
          <li>Food-grade silica gel chambers for precise moisture control</li>
          <li>Airtight sealing that prevents external humidity fluctuations</li>
        </ul>
      </p>
      <p>With HydroLid, you can ensure your cannabis stays in its best condition, enhancing your experience every time.</p>

      <h3>Best Practices for Storage</h3>
      <p>To maximize the effectiveness of your HydroLid and maintain the quality of your cannabis:
        <ul>
          <li>Store cannabis in a cool, dark place, away from direct sunlight and heat sources</li>
          <li>Keep humidity levels between 59-62% using a hygrometer to monitor</li>
          <li>Clean and completely dry your storage containers before use</li>
          <li>Replace the humidity control insert every 6-12 months</li>
        </ul>
      </p>
      <p>At Mellow Heights, we're committed to empowering cannabis enthusiasts with practical solutions and tips to elevate their cannabis storage and overall experience. Follow us for more insights and expert advice! 🌿✨</p>`,
      author: "Ryan Leahy",
      readTime: "5 min read",
      date: "Nov 15, 2024"
    },
    "The Essential Guide to Herbal Storage": {
      content: `
      <p>Whether you’re a culinary enthusiast or someone who enjoys the therapeutic benefits of herbs, proper storage is essential for keeping them fresh, flavorful, and effective. The way you store your herbs can make a big difference in preserving their essence and extending their shelf life.</p>

      <h3>Different Herbs, Different Needs</h3>
      <p>Not all herbs are created equal when it comes to storage. Here's a quick breakdown of the main types and their specific requirements:
        <ul>
          <li><strong>Leafy Herbs</strong> (like basil, mint): These are the most delicate and sensitive to changes in moisture, so they need extra care to stay vibrant.</li>
          <li><strong>Woody Herbs</strong> (like rosemary, thyme): A bit more resilient but still thrive in consistent conditions to maintain their potency.</li>
          <li><strong>Seeds/Pods</strong> (like fennel, cardamom): These need protection from light and air exposure to preserve their oils and flavors.</li>
        </ul>
      </p>

      <h3>Common Storage Mistakes</h3>
      <p>Even with the best intentions, it’s easy to make mistakes when storing herbs. Here are a few to avoid:
        <ul>
          <li>Using plastic containers, which can trap unwanted moisture and lead to spoilage.</li>
          <li>Storing different herbs together, which can mix flavors and lead to inconsistent freshness.</li>
          <li>Keeping herbs in areas prone to temperature swings, like near a stove or a sunny windowsill.</li>
          <li>Overlooking moisture buildup, which can quickly ruin even the best-quality herbs.</li>
        </ul>
      </p>

      <h3>Key Tips for Long-Lasting Freshness</h3>
      <p>When it comes to keeping herbs fresh, small changes can go a long way. Focus on these four factors to preserve your herbs:
        <ul>
          <li><strong>Light:</strong> Store herbs in opaque or amber glass containers to protect them from light exposure, which can degrade their color and potency.</li>
          <li><strong>Air:</strong> Use airtight containers to minimize oxygen exposure, which can dry out herbs and diminish their aroma.</li>
          <li><strong>Temperature:</strong> Keep herbs in a stable, cool environment—ideally around 68°F. Avoid placing them near heat sources.</li>
          <li><strong>Humidity:</strong> Control moisture levels carefully to prevent herbs from drying out or growing mold. A humidity-regulating tool, like HydroLid, can make this effortless.</li>
        </ul>
      </p>
      <p>Taking just a little extra time to store your herbs properly can make a world of difference in their flavor, fragrance, and effectiveness. At Mellow Heights, we believe in making these practices simple and accessible for everyone. 🌿</p>`,
      author: "Rory McAuliffe",
      readTime: "6 min read",
      date: "Dec 3, 2024"
    },
    "Sustainable Living with Mellow Heights": {
      content: `
      <p>At Mellow Heights, our mission goes beyond offering innovative products—we aim to educate and empower our community to make mindful choices. We believe that through education and sustainable practices, everyone can elevate their experience while reducing waste and embracing smarter solutions.</p>

      <h3>Empowering Through Education</h3>
      <p>Knowledge is the foundation of empowered living. That’s why we’re committed to sharing insights and practical tips on preserving the freshness of your herbs, cannabis, and spices. Proper storage isn’t just about convenience; it’s about preserving the essence of your ingredients and enhancing your experience. From humidity control techniques to practical storage solutions, we strive to make these practices simple and accessible for everyone.</p>

      <h3>Reducing Waste with HydroLid</h3>
      <p>One of the ways we contribute to sustainable living is through our HydroLid product. Unlike disposable humidity packs that need constant replacement, HydroLid offers a durable and reusable solution. By maintaining the ideal humidity levels, HydroLid keeps your herbs fresh for longer without the need for single-use products. This means fewer items end up in the trash, and you get a more consistent, reliable way to store your herbs.</p>
      <p>Here’s what sets HydroLid apart:
        <ul>
          <li>Two-way humidity regulation that reduces the need for wasteful replacements</li>
          <li>Durable materials designed for long-term use</li>
          <li>Airtight sealing to maintain freshness and eliminate spoilage</li>
        </ul>
      </p>

      <h3>Our Commitment to a Smarter Future</h3>
      <p>At Mellow Heights, we’re inspired by the opportunity to help our community make small changes that lead to big impacts. By choosing products like HydroLid, you’re not only extending the life of your herbs but also reducing reliance on wasteful, single-use solutions. It’s a win for your wallet, your kitchen, and your lifestyle.</p>

      <h3>Join the Movement</h3>
      <p>We invite you to join us on this journey of smarter living. Through education, innovative solutions, and community engagement, we’re building a future where freshness lasts longer, and waste becomes a thing of the past. Together, we can embrace a lifestyle that prioritizes quality, sustainability, and empowerment. ✨</p>
      `,
      author: "Mr Green",
      readTime: "4 min read",
      date: "March 20, 2024"
    }
  };

  const currentPost = blogContent[post.title];

  return (
    <div className="blog-modal-overlay">
      <div className="blog-modal">
        <header className="blog-modal-header">
          <h2>{post.title}</h2>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </header>
        <div className="blog-modal-content">
          <div className="blog-meta">
            <span className="blog-author">{currentPost.author}</span>
            <span className="blog-date">{currentPost.date}</span>
            <span className="blog-read-time">{currentPost.readTime}</span>
          </div>
          <div 
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: currentPost.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogPostModal;