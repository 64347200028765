import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Home } from 'lucide-react';
import '../stylings/NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(10); // 10 seconds countdown
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/');
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
      setProgress((prevProgress) => prevProgress - 10);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, navigate]);

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        {/* Custom 404 Image */}
        <div className="not-found-circle">
          <span className="not-found-number">404</span>
        </div>

        <h1 className="not-found-title">
          Page Not Found
        </h1>
        
        <p className="not-found-message">
          Oops! It seems like you've wandered into uncharted territory. 
          Let's get you back on track.
        </p>

        {/* Progress Bar */}
        <div className="redirect-timer">
          <div className="progress-bar-container">
            <div 
              className="progress-bar" 
              style={{ width: `${progress}%` }}
            />
          </div>
          <p className="timer-text">
            Redirecting to home in {timeLeft} seconds...
          </p>
        </div>

        <div className="not-found-buttons">
          <button 
            onClick={() => navigate(-1)}
            className="not-found-button back-button"
          >
            <ArrowLeft className="button-icon" />
            Go Back
          </button>

          <button 
            onClick={() => navigate('/')}
            className="not-found-button home-button"
          >
            <Home className="button-icon" />
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;