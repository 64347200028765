import React, { useState } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { Users, ShoppingBag, Clock, Globe, MousePointer, Navigation } from 'lucide-react';
import '../stylings/AdminMellowAnalytics.css';

const mockData = {
  trafficData: [
    { date: '2024-01-01', visits: 1200, uniqueVisitors: 800 },
    { date: '2024-01-02', visits: 1400, uniqueVisitors: 950 },
    { date: '2024-01-03', visits: 1100, uniqueVisitors: 750 },
    { date: '2024-01-04', visits: 1600, uniqueVisitors: 1100 },
    { date: '2024-01-05', visits: 1800, uniqueVisitors: 1300 },
  ],
  userActions: [
    { 
      action: 'Product Views',
      count: 2500,
      details: {
        average: '4.2 views per user',
        peak: '342 views/hour',
        trend: '+15% from last period',
        mostViewed: 'Mellow Heights Classic'
      }
    },
    { 
      action: 'Add to Cart',
      count: 800,
      details: {
        conversion: '32% of viewers',
        average: '2.1 items per cart',
        peak: '95 adds/hour',
        topProduct: 'Mellow Heights Pro'
      }
    },
    { 
      action: 'Checkouts',
      count: 400,
      details: {
        completion: '50% of cart adds',
        average: '$145 per checkout',
        peak: '45 checkouts/hour',
        abandonRate: '25%'
      }
    },
    { 
      action: 'Purchases',
      count: 300,
      details: {
        conversion: '75% of checkouts',
        revenue: '$43,500 total',
        average: '$145 per order',
        repeatBuyers: '15%'
      }
    },
  ],
  deviceData: [
    { device: 'Desktop', percentage: 45 },
    { device: 'Mobile', percentage: 40 },
    { device: 'Tablet', percentage: 15 },
  ]
};

const ActionTooltip = ({ details }) => (
  <div className="action-tooltip">
    <div className="tooltip-grid">
      {Object.entries(details).map(([key, value]) => (
        <div key={key} className="tooltip-item">
          <span className="tooltip-label">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
          <span className="tooltip-value">{value}</span>
        </div>
      ))}
    </div>
  </div>
);

const AdminMellowAnalytics = () => {
  const [timeRange, setTimeRange] = useState('7days');
  const [chartType, setChartType] = useState('line');

  return (
    <div className="analytics-wrapper">
      <div className="analytics-header">
        <div className="header-controls">
          <select 
            value={timeRange} 
            onChange={(e) => setTimeRange(e.target.value)}
            className="form-select"
          >
            <option value="24h">Last 24 Hours</option>
            <option value="7days">Last 7 Days</option>
            <option value="30days">Last 30 Days</option>
            <option value="90days">Last 90 Days</option>
          </select>

          <select 
            value={chartType} 
            onChange={(e) => setChartType(e.target.value)}
            className="form-select"
          >
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
        </div>
      </div>

      <div className="analytics-grid">
        <div className="analytics-card">
          <div className="card-header">
            <Users className="card-icon" />
            <h3>Active Users</h3>
          </div>
          <div className="card-content">
            <p className="card-value">1,234</p>
            <p className="card-trend positive">+12.5%</p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="card-header">
            <ShoppingBag className="card-icon" />
            <h3>Conversion Rate</h3>
          </div>
          <div className="card-content">
            <p className="card-value">3.8%</p>
            <p className="card-trend positive">+2.1%</p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="card-header">
            <Clock className="card-icon" />
            <h3>Avg. Session</h3>
          </div>
          <div className="card-content">
            <p className="card-value">4m 32s</p>
            <p className="card-trend negative">-0.5%</p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="card-header">
            <Globe className="card-icon" />
            <h3>Bounce Rate</h3>
          </div>
          <div className="card-content">
            <p className="card-value">42.3%</p>
            <p className="card-trend positive">-3.2%</p>
          </div>
        </div>
      </div>

      <div className="chart-container">
        <h3>Website Traffic</h3>
        <ResponsiveContainer width="100%" height={300}>
          {chartType === 'line' ? (
            <LineChart data={mockData.trafficData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="visits" stroke="#8fc760" />
              <Line type="monotone" dataKey="uniqueVisitors" stroke="#82ca9d" />
            </LineChart>
          ) : chartType === 'bar' ? (
            <BarChart data={mockData.trafficData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="visits" fill="#8fc760" />
              <Bar dataKey="uniqueVisitors" fill="#82ca9d" />
            </BarChart>
          ) : (
            <AreaChart data={mockData.trafficData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="visits" stroke="#8fc760" fill="#8fc760" fillOpacity={0.3} />
              <Area type="monotone" dataKey="uniqueVisitors" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.3} />
            </AreaChart>
          )}
        </ResponsiveContainer>
      </div>

      <div className="analytics-sections">
        <div className="section user-actions">
          <h3>User Actions</h3>
          <div className="action-bars">
            {mockData.userActions.map((action, index) => (
              <div key={index} className="action-bar">
                <div className="action-label">{action.action}</div>
                <div className="action-track">
                  <div 
                    className="action-progress" 
                    style={{ width: `${(action.count / 3000) * 100}%` }}
                  />
                  <span className="action-value">{action.count}</span>
                  <div className="action-tooltip-wrapper">
                    <ActionTooltip details={action.details} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="section device-breakdown">
          <h3>Device Breakdown</h3>
          <div className="device-stats">
            {mockData.deviceData.map((device, index) => (
              <div key={index} className="device-stat">
                <div className="device-info">
                  <span className="device-name">{device.device}</span>
                  <span className="device-percentage">{device.percentage}%</span>
                </div>
                <div className="device-bar">
                  <div 
                    className="device-progress"
                    style={{ width: `${device.percentage}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMellowAnalytics;