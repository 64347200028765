import React, { useState } from 'react';
import { X, UserPlus, Settings as SettingsIcon, BarChart2 } from 'lucide-react';
import AdminMellowAnalytics from './AdminMellowAnalytics';
import '../stylings/SettingsModal.css';

const SettingsModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    email: '',
    role: 'admin'
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch('https://sbojfulrpk.execute-api.us-east-1.amazonaws.com/default/mellowAddAdminUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser)
      });

      const data = await response.json();
      
      if (response.ok) {
        setSuccess('User created successfully');
        setNewUser({ username: '', password: '', email: '', role: 'admin' });
      } else {
        setError(data.message || 'Failed to create user');
      }
    } catch (err) {
      setError('Network error occurred');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 className="modal-title">Dashboard Settings</h2>
          <button onClick={onClose} className="close-button">
            <X size={24} />
          </button>
        </div>

        <div className="tab-navigation">
          <button
            className={`tab-button ${activeTab === 'general' ? 'active' : ''}`}
            onClick={() => setActiveTab('general')}
          >
            <SettingsIcon size={18} />
            General
          </button>
          <button
            className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            <UserPlus size={18} />
            Users
          </button>
          <button
            className={`tab-button ${activeTab === 'analytics' ? 'active' : ''}`}
            onClick={() => setActiveTab('analytics')}
          >
            <BarChart2 size={18} />
            Analytics
          </button>
        </div>

        <div className="modal-content">
          {activeTab === 'general' && (
            <div className="settings-grid space-y-4">
              <h3 className="section-title">General Settings</h3>
              <div className="form-group">
                <label className="form-label">Dashboard Theme</label>
                <select className="form-select">
                  <option>Light</option>
                  <option>Dark</option>
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Notification Settings</label>
                <div className="checkbox-wrapper">
                  <input type="checkbox" className="checkbox-input" id="email-notifications" />
                  <label className="checkbox-label" htmlFor="email-notifications">Email notifications</label>
                </div>
                <div className="checkbox-wrapper">
                  <input type="checkbox" className="checkbox-input" id="order-alerts" />
                  <label className="checkbox-label" htmlFor="order-alerts">Order alerts</label>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'users' && (
            <div className="space-y-6">
              <h3 className="section-title">Create New User</h3>
              {error && <div className="error-message">{error}</div>}
              {success && <div className="success-message">{success}</div>}
              <form onSubmit={handleCreateUser} className="space-y-4">
                <div className="form-group">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    value={newUser.username}
                    onChange={(e) => setNewUser({...newUser, username: e.target.value})}
                    className="form-input"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    value={newUser.email}
                    onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                    className="form-input"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <input
                    type="password"
                    value={newUser.password}
                    onChange={(e) => setNewUser({...newUser, password: e.target.value})}
                    className="form-input"
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Role</label>
                  <select
                    value={newUser.role}
                    onChange={(e) => setNewUser({...newUser, role: e.target.value})}
                    className="form-select"
                    required
                  >
                    <option value="admin">Admin</option>
                    <option value="assembler">Assembler</option>
                    <option value="IT">IT</option>
                  </select>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="submit-button"
                >
                  {loading ? 'Creating...' : 'Create User'}
                </button>
              </form>
            </div>
          )}

          {activeTab === 'analytics' && (
            <div className="analytics-tab-container">
              <AdminMellowAnalytics />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;